import { useNavigate, useParams } from 'react-router-dom'
import i18n from '~/i18n'
import { DEFAULT_LANG } from '~/locales'
import { selectAuth } from '~/redux/features/auth/auth-slice'
import { useAppSelector } from '~/redux/hooks'

export type ProtectedRouteProps = boolean

const useProtectedRoute = (isProtected: ProtectedRouteProps) => {
  const { authenticated, account_detail } = useAppSelector(selectAuth)
  const navigate = useNavigate()
  const { lang } = useParams()

  if (isProtected && authenticated && !account_detail.is_active) {
    return navigate('/account/request-link')
  }

  if (isProtected && !authenticated) {
    let routingPath = '/login'

    if (lang && i18n.language !== DEFAULT_LANG) {
      routingPath = `/${i18n.language}`.concat(routingPath)
    }

    console.log('entering protected route', { lang, isProtected, authenticated })
    console.log('redirecting to', routingPath)
    return navigate(routingPath)
  }
}

export default useProtectedRoute
