import ActivationPage from '~/pages/account/activation/Activate'
import RequestLink from '~/pages/account/activation/RequestLink'

export const accountRoutes = [
  {
    path: 'account',
    children: [
      { path: 'request-link', element: <RequestLink /> },
      {
        path: 'validation/:uuid/:token',
        element: <ActivationPage />,
      },
    ],
  },
]
