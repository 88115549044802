import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

// PasswordReset schema and hook
export type UseZodPasswordResetSchema = z.infer<ReturnType<typeof useZodPasswordResetSchema>>

export const useZodPasswordReset = (defaultValues: Partial<UseZodPasswordResetSchema> = {}) => {
  const schema = useZodPasswordResetSchema()

  return useForm<UseZodPasswordResetSchema>({
    resolver: zodResolver(schema),
    defaultValues,
  })
}

export const useZodPasswordResetSchema = () => {
  const { t } = useTranslation()

  return z
    .object({
      password: z.string().min(8, { message: t('ui.form.password_min_length', { value: 8 }) }),
      password2: z.string().min(8, { message: t('ui.form.password_min_length', { value: 8 }) }),
    })
    .superRefine((data, ctx) => {
      if (data.password && data.password2 && data.password !== data.password2) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('ui.form.password_not_match'),
          path: ['password2'],
        })
      }
    })
}
