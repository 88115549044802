import about from './about'
import activation from './activate'
import contactUs from './contactUs'

const page = {
  about,
  activation,
  contactUs,
}
export default page
