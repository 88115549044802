import { BoxProps } from '@mui/material/Box'
import { ComponentProps, FC, PropsWithChildren } from 'react'
import { Form as Component } from './Form.styled'

export type FormProps = {
  size?: 'small' | 'normal'
} & ComponentProps<'form'> &
  Omit<BoxProps, 'component'>

/**
 * Form component that wraps its children in a styled form element.
 *
 * @param {Object} props - The properties object.
 * @param {'register' | 'normal'} [props.type='normal'] - The type of the form, which can be either 'register' or 'normal'.
 * @param {React.ReactNode} props.children - The child elements to be rendered inside the form.
 * @param {Object} props.rest - Additional properties passed to the form element.
 *
 * @returns {JSX.Element} The rendered form component.
 */
const Form: FC<PropsWithChildren<FormProps>> = ({ children, size = 'normal', ...props }) => (
  <Component component="form" size={size} {...props}>
    {children}
  </Component>
)

export default Form
