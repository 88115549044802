import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { ACCOUNT_TYPES } from '~/constants/account'

// Profile schema and hook
export type UseZodProfileSchema = z.infer<ReturnType<typeof useZodProfileSchema>>

export const useZodProfile = (defaultValues: Partial<UseZodProfileSchema> = {}) => {
  const schema = useZodProfileSchema()

  return useForm<UseZodProfileSchema>({
    resolver: zodResolver(schema),
    defaultValues: {
      ...defaultValues,
      password: '',
      password2: '',
      certificate: undefined,
      avatar: undefined,
    },
  })
}

const useZodProfileSchema = () => {
  const { t } = useTranslation()

  return z
    .object({
      first_name: z.string().min(2, { message: t('ui.form.first_name_min_length', { value: 2 }) }),
      last_name: z.string().min(2, { message: t('ui.form.last_name_min_length', { value: 2 }) }),
      email: z.string().email({ message: t('ui.form.email_invalid') }),
      password: z.string().optional(),
      password2: z.string().optional(),
      account_type: z.enum(ACCOUNT_TYPES).optional(),
      certificate: z.instanceof(File).optional(),
      avatar: z.union([z.string().url(), z.instanceof(File)]).optional(),
    })
    .superRefine((data, ctx) => {
      if (data.password && data.password2 && data.password !== data.password2) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('ui.form.password_not_match'),
          path: ['password2'],
        })
      }
    })
}
