import { ACCOUNT_TYPES } from '~/constants/account'
import { Prettify } from '~/helpers/types/app'
import { UserAccount } from '~/helpers/types/user-account'
import { apiSlice } from './api-slice'

export type AccountCommonFormDataValues = {
  first_name: string
  last_name: string
}

export type PublicAccountDetails = Prettify<
  {
    id: number
    avatar: string
    account_type: (typeof ACCOUNT_TYPES)[number]
    certificate: File | null
  } & AccountCommonFormDataValues
>

export type AccountSettings = {
  language?: string
  cookie_set?: { type: string; accepted: boolean }[]
}

function formDataToObject(data: Record<string, any>): Record<string, any> {
  // Create a FormData object to hold the form data
  const formData = new FormData()

  // Append all form fields to the FormData object
  for (const key in data) {
    if (data[key] instanceof FileList) {
      // Handle file inputs (assuming single file input)
      formData.append(key, data[key][0])
    } else {
      formData.append(key, data[key])
    }
  }

  // console.log('\n=== formData ===')
  // console.log(formData)
  // console.log('===\n')

  return formData
}

export const accountApiSlice = apiSlice
  .enhanceEndpoints({ addTagTypes: ['AccountDetail'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      register: builder.mutation({
        query: (data) => ({
          url: '/account/register/',
          method: 'POST',
          body: formDataToObject(data),
        }),
      }),
      updateCommon: builder.mutation({
        query: (data) => ({
          url: '/account/',
          method: 'PUT',
          body: formDataToObject(data),
        }),
      }),
      updateSettings: builder.mutation<AccountSettings, AccountSettings>({
        query: (data) => ({
          url: '/account/settings/',
          method: 'PATCH',
          body: { ...data },
        }),
      }),
      getAccount: builder.query<UserAccount, void>({
        query: () => ({ url: '/account/detail' }),
        providesTags: ['AccountDetail'],
      }),
      getAccountPublic: builder.query({
        query: ({ userId }) => ({
          url: `/account/${userId}/public`,
        }),
      }),
      activation: builder.query({
        query: ({ uuid, token }) => ({
          url: `/account/activate/${uuid}/${token}`,
          method: 'GET',
        }),
      }),
    }),
  })

export const {
  useActivationQuery,
  useGetAccountPublicQuery,
  useGetAccountQuery,
  useRegisterMutation,
  useUpdateCommonMutation,
  useUpdateSettingsMutation,
} = accountApiSlice
