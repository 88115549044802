import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { ACCOUNT_TYPES } from '~/constants/account'
import { useZodLoginSchema } from './useZodLogin'

// Register schema and hook
export type UseZodRegisterSchema = z.infer<ReturnType<typeof useZodRegisterSchema>>

export const useZodRegister = (defaultValues: Partial<UseZodRegisterSchema> = {}) => {
  const schema = useZodRegisterSchema()

  return useForm<UseZodRegisterSchema>({
    resolver: zodResolver(schema),
    defaultValues,
  })
}

const useZodRegisterSchema = () => {
  const { t } = useTranslation()
  const baseSchema = useZodLoginSchema()

  return baseSchema
    .extend({
      account_type: z.enum(ACCOUNT_TYPES),
      first_name: z.string().min(2, { message: t('ui.form.first_name_min_length', { value: 2 }) }),
      last_name: z.string().min(2, { message: t('ui.form.last_name_min_length', { value: 2 }) }),
      password2: z.string().min(8, { message: t('ui.form.password_min_length') }),
      certificate: z.instanceof(File).optional(),
    })
    .superRefine((data, ctx) => {
      if (data.password && data.password2 && data.password !== data.password2) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('ui.form.password_not_match'),
          path: ['password2'],
        })
      }
      if (data.account_type === 'doctor' && !data.certificate) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('ui.form.certificate_required'),
          path: ['certificate'],
        })
      }
    })
}
