import { Typography } from '@mui/material'
import FormActivationRequestLink from '~/components/FormActivationRequestLink'
import { Col, Row } from '~/components/UI/Grid'
import Section from '~/components/UI/Section'

const RequestLink = () => {
  return (
    <Section size="small">
      <Row>
        <Col xs={12}>
          <Typography variant="h4">Resend Activation Email</Typography>

          <Typography variant="body1" mt={3}>
            If you haven&rsquo;t received the activation email, or if it has expired, you can
            request a new one. Please enter the email address associated with your account, and
            we&rsquo;ll send you a new activation link.
          </Typography>
        </Col>

        <Col xs={12} mt={3}>
          <FormActivationRequestLink />
        </Col>
      </Row>
    </Section>
  )
}
export default RequestLink
