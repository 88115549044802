import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

export type UseZodContactUsSchema = z.infer<ReturnType<typeof useZodContactUsSchema>>

export const useZodContactUs = (defaultValues: Partial<UseZodContactUsSchema> = {}) => {
  const schema = useZodContactUsSchema()
  return useForm<UseZodContactUsSchema>({
    resolver: zodResolver(schema),
    defaultValues,
  })
}

const useZodContactUsSchema = () => {
  const { t } = useTranslation()

  return z.object({
    name: z.string().min(2, { message: t('ui.form.name_min_length', { value: 2 }) }),
    email: z.string().email({ message: t('ui.form.email_invalid') }),
    subject: z.string().min(2, { message: t('ui.form.subject_min_length', { value: 2 }) }),
    message: z.string().min(2, { message: t('ui.form.message_min_length', { value: 2 }) }),
  })
}
