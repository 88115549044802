import FormPasswordReset from '~/components/FormPasswordReset'
import Section from '~/components/UI/Section'

const PasswordReset = () => {
  return (
    <Section size="small">
      <FormPasswordReset />
    </Section>
  )
}
export default PasswordReset
