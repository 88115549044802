const t = {
  error: {
    title: 'Email Verification Failed',
    subtitle:
      'Unfortunately, we were unable to verify your email address. This could be due to an expired link or an issue with our system',
    content: '',
    buttonGroup: [
      {
        id: 'vb4h53gref',
        label: 'Resend Verification Link',
        href: '/auth/login',
        variant: 'outlined',
        color: 'primary',
      },
      {
        id: 'ojf21vbb20',
        label: 'Contact Support',
        href: '/contact-us',
        variant: 'outlined',
        color: 'primary',
      },
    ],
  },
  success: {
    title: 'Welcome to Given Health.',
    subtitle: '',
    content: 'Email Verified! Begin using our services by logging in to your account.',
    buttonGroup: [
      {
        id: 'ojf21vbb20',
        label: 'Login to Your Account',
        href: '/login',
        variant: 'contained',
        color: 'primary',
      },
    ],
  },
  error_message: 'Activation failed.',
  success_message: 'Your account has been activated successfully',
  api_error_message: 'Something went wrong. Please try again later.',
}

export default t
