import FormPasswordRequestReset from '~/components/FormPasswordRequestReset'
import Section from '~/components/UI/Section'

const PasswordReset = () => {
  return (
    <Section size="small">
      <FormPasswordRequestReset />
    </Section>
  )
}
export default PasswordReset
